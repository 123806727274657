import { faSnowplow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch } from "../../app/hooks";
import { clearAll } from "../../app/store";
import { useTranslations } from "../i18n";

export function ResetButton() {
    const dispatch = useAppDispatch();
    const t = useTranslations();

    return <div
        className="icon-button"
        onClick={() => {
            if (window.confirm("Are you sure you want to clear all content on the page?"))
                dispatch(clearAll())
        }}
        title={t.clearAll}
    >
        <FontAwesomeIcon icon={faSnowplow} />
    </div>
}