import { languages, setLanguage, useTranslations } from "."
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import "./SwitchLanguageButton.css";

export default function SwitchLanguageButton() {
    const dispatch = useAppDispatch();
    const otherLanguage = useAppSelector(state => state.language === "deDe" ? "enUs" as const : "deDe" as const);
    const t = useTranslations();

    return <div
        className="switch-language-button icon-button"
        onClick={() => dispatch(setLanguage(otherLanguage))}
        title={t.switchLanguage}
    >
        {languages[otherLanguage].languageName}
    </div>
}