import React from "react";

/**
 * Provides the props required for a contenteditable.  
 * Hides spellcheck error squiggles while the element is not selected.
 **/
export function useContentEditableProps(onChange: (text: string) => void): React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
    const [spellCheck, setSpellCheck] = React.useState(false);
    return {
        ...simpleContentEditableProps,
        onBlur: e => {
            onChange(e.currentTarget.innerText);
            setSpellCheck(false);
        },
        onFocus: () => setSpellCheck(true),
        spellCheck: spellCheck
    };
}

/** 
 * The contenteditable settings to use for places where a single line without 
 * pasteable formatting should be edited. (That should be most places.)
 **/
export const simpleContentEditableProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> = {
    contentEditable: true,
    suppressContentEditableWarning: true,
    onKeyDown: e => e.key === "Enter" && e.currentTarget.blur(),
    onPaste: e => {
        if (!("execCommand" in document))
            return;
        e.preventDefault();
        const text = e.clipboardData.getData("text/plain");
        document.execCommand("insertText", false, text);
    },
    inputMode: "text",
};
