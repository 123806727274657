import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TitleType = "title" | "pro" | "contra";

export type TitleState = {
    [key in TitleType]: string;
}

export const titleSlice = createSlice({
    name: "titles",
    initialState: {
        title: "Argumentationswippe",
        pro: "Pro",
        contra: "Contra",
    } as TitleState,
    reducers: {
        setTitle(state, action: PayloadAction<{ field: TitleType, text: string }>) {
            state[action.payload.field] = action.payload.text;
        }
    }
});

export const { setTitle } = titleSlice.actions;
