import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ArgumentColor, argumentColors } from "../arguments/argumentsSlice";

type LegendState = { [key in ArgumentColor]: string }

const initialState = Object.fromEntries(argumentColors.map(x => [x, ""])) as LegendState;

export const legendSlice = createSlice({
    name: "legend",
    initialState,
    reducers: {
        setLegendText(state, action: PayloadAction<{ color: ArgumentColor, text: string }>) {
            const { color, text } = action.payload;
            state[color] = text;
        }
    }
});

export const { setLegendText } = legendSlice.actions;
