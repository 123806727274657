import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import "./patterns.css";

export const patternsSlice = createSlice({
    name: "patternsActive",
    initialState: false,
    reducers: {
        setPatternsActive(_, action: PayloadAction<boolean>) {
            return action.payload;
        }
    }
});

export const { setPatternsActive } = patternsSlice.actions;
