import { Middleware } from "@reduxjs/toolkit";
import { RootState, setState, store } from "../../app/store";

export function loadSettings() {
    const settings = localStorage.getItem("settings");
    if (!settings) return;
    const newState = { ...store.getState(), ...JSON.parse(settings) };
    store.dispatch(setState(newState));
}

const savedSlices: (keyof RootState)[] = ["colorTheme", "language", "patternsActive"];

export const saveSettingsMiddleware: Middleware = store => next => action => {
    const oldState = store.getState();
    next(action);
    const newState = store.getState();
    const hasChanges = savedSlices.some(slice => oldState[slice] !== newState[slice]);
    if (hasChanges) {
        const savedState = Object.fromEntries(savedSlices.map(slice => [slice, newState[slice]]))
        localStorage.setItem("settings", JSON.stringify(savedState));
    }
}
