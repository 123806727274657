import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ArgumentListKey = "unsorted" | "pro3" | "pro2" | "pro1" | "con1" | "con2" | "con3"

const argumentListKeys: readonly ArgumentListKey[] = ["unsorted", "pro3", "pro2", "pro1", "con1", "con2", "con3"]
export type ArgumentColor = "red" | "green" | "yellow" | "lightblue" | "blue" | "darkblue";
export const argumentColors: ArgumentColor[] = ["red", "green", "yellow", "lightblue", "blue", "darkblue"]
export interface Argument {
    text: string;
    color: ArgumentColor;
}

export type ArgumentsState = { [key in ArgumentListKey]: Argument[] }
export const defaultColor: ArgumentColor = "blue";



const initialState = Object.fromEntries(argumentListKeys.map(x => [x, []])) as unknown as ArgumentsState;

export type ArgumentIndex = {
    list: ArgumentListKey,
    index: number
}

export const argumentsSlice = createSlice({
    name: "arguments",
    initialState,
    reducers: {
        addArgument(state, action: PayloadAction<string>) {
            if (action.payload.trim().length === 0) return;
            state.unsorted.push({ text: action.payload, color: defaultColor });
        },
        setArgumentText(state, action: PayloadAction<{ target: ArgumentIndex, text: string }>) {
            const { target, text } = action.payload;
            state[target.list][target.index].text = text;
        },
        setArgumentColor(state, action: PayloadAction<{ target: ArgumentIndex, color: ArgumentColor }>) {
            const { target, color } = action.payload;
            state[target.list][target.index].color = color;
        },
        moveArgument(state, action: PayloadAction<{ source: ArgumentIndex, destination: ArgumentIndex }>) {
            const { source, destination } = action.payload;
            // TODO: check if index exists
            const [argument] = state[source.list].splice(source.index, 1);
            const target = state[destination.list];
            state[destination.list] = [...target.slice(0, destination.index), argument, ...target.slice(destination.index)]
        },
        deleteArgument(state, action: PayloadAction<ArgumentIndex>) {
            const { list, index } = action.payload;
            state[list].splice(index, 1);
        }
    }
})

export const { addArgument, setArgumentText, setArgumentColor, moveArgument, deleteArgument } = argumentsSlice.actions;
