import React from 'react';
import './App.css';
import { DragDropContext, DraggableLocation, DropResult } from "react-beautiful-dnd";
import { ArgumentList } from './features/arguments/ArgumentList';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { addArgument, ArgumentListKey, deleteArgument, moveArgument } from './features/arguments/argumentsSlice';
import classNames from 'classnames';
import { TrashCan } from './features/arguments/TrashCan';
import { TitleComponent } from './features/title/TitleComponent';
import { LoadButton, SaveButton } from './features/save-load/Buttons';
import { setState } from './app/store';
import { ResetButton } from './features/reset-all/ResetButton';
import { Footer } from './features/footer/Footer';
import { SwitchColorButton } from './features/switch-color-theme/SwitchColorButton';
import { SwitchPatternsButton } from './features/patterns/SwitchPatternsButton';
import { Legend } from './features/legend/Legend';
import SwitchLanguageButton from './features/i18n/SwitchLanguageButton';
import { ZenModeButton } from './features/zen-mode/ZenModeButton';
import { simpleContentEditableProps } from './utils/useContentEditableProps';

function toArgumentIndex(location: DraggableLocation) {
  return {
    list: location.droppableId as ArgumentListKey,
    index: location.index
  }
}

function App() {
  const dispatch = useAppDispatch();

  const balance = useAppSelector(state => {
    let balance = state.arguments.pro1.length * 1
      + state.arguments.pro2.length * 2
      + state.arguments.pro3.length * 3
      - state.arguments.con1.length * 1
      - state.arguments.con2.length * 2
      - state.arguments.con3.length * 3
    balance = Math.max(balance, -5);
    balance = Math.min(balance, 5)
    return balance;
  });

  const onDragEnd = (e: DropResult) => {
    if (!e.destination) return;
    if (e.destination.droppableId === "trashCan") {
      dispatch(deleteArgument(toArgumentIndex(e.source)))
    }
    else {
      dispatch(moveArgument({
        source: toArgumentIndex(e.source),
        destination: toArgumentIndex(e.destination)
      }));
    }
  }

  return (
    <div
      className="App"
      onDrop={async e => {
        if (e.dataTransfer.items.length !== 1) return;
        e.preventDefault();
        e.stopPropagation();
        const file = e.dataTransfer.items[0];
        const stringPromise = file.getAsFile()?.text() ?? new Promise(resolve => file.getAsString(resolve));
        const data = JSON.parse(await stringPromise);
        dispatch(setState(data));
      }}
      onDragOver={e => e.preventDefault()}
    >
      <div className="buttons-right">
        <ZenModeButton />
        <SwitchColorButton />
        <SwitchPatternsButton />
        <SwitchLanguageButton />
      </div>

      <TitleComponent field="title" />

      <div className="buttons-left">
        <ResetButton />
        <SaveButton />
        <LoadButton />
        <Legend />
      </div>
      <DragDropContext
        onDragEnd={onDragEnd}>
        <div className="unsorted-list">
          <ArgumentList listKey='unsorted'>
            <PlusButton />
          </ArgumentList >
        </div>
        <div className="seesaw" style={{ transform: `rotate(${-balance}deg)` }}>
          <div className="sorted-lists" >
            <ArgumentList listKey='pro3' />
            <ArgumentList listKey='pro2' />
            <ArgumentList listKey='pro1' />
            <ArgumentList listKey='con1' />
            <ArgumentList listKey='con2' />
            <ArgumentList listKey='con3' />
          </div>
          <div className="beam" />
        </div>


        <div className="bottom-row">
          <TitleComponent field="pro" />
          <TrashCan />
          <TitleComponent field="contra" />
        </div>
      </DragDropContext>

      <Footer />

      <div id="dropdown-container"></div>
    </div >
  );
}

function PlusButton() {
  const [isEditing, setIsEditing] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>();
  const dispatch = useAppDispatch();

  if (isEditing) { ref.current?.focus({ preventScroll: true }); }

  return <div className={classNames("plus-button", { isEditing })}
    onClick={() => {
      setIsEditing(true);
      setTimeout(() => ref.current?.focus());
    }}
    onBlur={() => {
      // Option A: blur = enter
      if (!ref?.current) return;
      dispatch(addArgument(ref.current.innerText));
      ref.current.innerText = "";
      setIsEditing(false);

      // Option B: only disable editing when empty
      // if (ref.current?.innerText.trim().length === 0) {
      //   setIsEditing(false);
      //   ref.current.innerText = " ";
      // }
    }}
    onKeyDown={(e) => {
      if (e.key === "Enter") {
        if (!ref?.current) return;
        dispatch(addArgument(ref.current.innerText));
        ref.current.innerText = "";
        e.preventDefault();
      }
    }}
  >
    {isEditing ?
      <div
        {...simpleContentEditableProps}
        ref={x => {
          if (x) {
            x.focus();
            ref.current = x;
          }
        }}
      ></div> :
      <div>+</div>}
  </div>
}

export default App;
