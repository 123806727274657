import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Droppable } from "react-beautiful-dnd";
import "./TrashCan.css"

export function TrashCan() {
    return <Droppable droppableId="trashCan">
        {(provided, snapshot) => <div
            className={classNames("trash-can", { dragTarget: snapshot.isDraggingOver })}
            {...provided.droppableProps}
            ref={provided.innerRef}>
            <FontAwesomeIcon icon={faTrash} />
        </div>}
    </Droppable>
}