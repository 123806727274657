import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useTranslations } from "../i18n";
import { setPatternsActive } from "./patternsSlice";
import "./SwitchPatternsButton.css"

export function SwitchPatternsButton() {
    const dispatch = useAppDispatch();
    const patternsActive = useAppSelector(state => state.patternsActive);
    const t = useTranslations();

    React.useEffect(() => {
        document.body.classList.remove("patterns-enabled");
        if (patternsActive)
            document.body.classList.add("patterns-enabled");
    }, [patternsActive]);

    return <div
        onClick={() => dispatch(setPatternsActive(!patternsActive))}
        className="icon-button switch-patterns-button"
        title={patternsActive ? t.disablePatterns : t.enablePatterns}
    >
        <div />
    </div>;
}
