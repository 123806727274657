import { Draggable, DraggableChildrenFn, Droppable } from "react-beautiful-dnd";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Argument, ArgumentListKey, setArgumentText } from "./argumentsSlice";
import "./ArgumentList.css";
import classNames from "classnames";
import { ColorDropdownButton } from "./ColorDropdown";
import React from "react";
import MardownIt from "markdown-it";
import { simpleContentEditableProps } from "../../utils/useContentEditableProps";

export function ArgumentList(props: { listKey: ArgumentListKey, children?: JSX.Element }) {
    const _arguments = useAppSelector(s => s.arguments[props.listKey]);
    const renderItemFunction = getRenderItem(_arguments);
    return <Droppable droppableId={props.listKey}
        renderClone={renderItemFunction}>
        {provided => < div className="list" {...provided.droppableProps} ref={provided.innerRef} >
            {
                _arguments.map((arg, index) => <Draggable key={index} draggableId={`${props.listKey}-${arg}-${index}`} index={index}>
                    {renderItemFunction}
                </Draggable>)
            }
            {provided.placeholder}
            {props.children}
        </div >
        }
    </Droppable >
}

// as seen here:
// https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/reparenting.md
const getRenderItem = (args: Argument[]): DraggableChildrenFn => (provided, snapshot, rubric) => {
    const index = rubric.source.index;
    const list = rubric.source.droppableId as ArgumentListKey;
    const { text, color } = args[index];
    const [isEditing, setIsEditing] = React.useState(false);
    const dispatch = useAppDispatch();

    return <div
        className={classNames("argument", {
            dragged: snapshot.isDragging && !snapshot.isDropAnimating,
            dropping: snapshot.isDropAnimating,
            trashed: snapshot.isDropAnimating && snapshot.draggingOver === "trashCan",
            [`argument-color-${color}`]: color !== undefined,
            'argument-uncolored': color === undefined,
        })}
        key={index}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
    >
        <AutoScalingDiv key={text} >
            {isEditing ?
                <EditableArgumentText
                    text={text}
                    onBlur={text => {
                        dispatch(setArgumentText({ target: { index, list }, text }));
                        setIsEditing(false)
                    }} /> :
                <MarkdownInline rawText={text} />
            }
        </AutoScalingDiv>

        <ColorDropdownButton argument={{ index, list }} startEdit={() => setIsEditing(true)} />
    </div>
};

function EditableArgumentText(props: { text: string, onBlur: (text: string) => void }) {
    return <span
        {...simpleContentEditableProps}
        onLoad={e => e.currentTarget.focus()}
        ref={x => {
            if (!x) return;
            const selection = document.getSelection();
            if (selection) {
                const range = new Range();
                range.selectNodeContents(x)
                selection.removeAllRanges();
                selection.addRange(range);
            }
            x.focus();
        }}
        onBlur={e => props.onBlur(e.currentTarget.innerText)}
    >
        {props.text}
    </span>
}

function AutoScalingDiv(props: { children: React.ReactNode }) {
    const { children } = props;
    const [scale, setScale] = React.useState(0);
    const [overflowing, setOverflowing] = React.useState(false);
    const lastSizeRef = React.useRef(0);
    const ref = React.useRef<HTMLDivElement>();
    const updateFontSize = React.useCallback((chainLength = 0) => {
        if (chainLength > 3) return;
        const element = ref.current;
        if (!element) return;
        lastSizeRef.current = element.clientHeight;
        if (element.scrollHeight > element.clientHeight) {
            setScale(scale => {
                const newScale = Math.max(scale - 1, -2);
                if (scale !== newScale)
                    setTimeout(() => updateFontSize(chainLength + 1));
                if (scale === -2)
                    setOverflowing(true);
                return newScale;
            });
        }
        else if (element.clientHeight > lastSizeRef.current) {
            setScale(scale => {
                const newScale = Math.min(scale + 1, 0);
                if (scale !== newScale)
                    setTimeout(() => updateFontSize(chainLength + 1));
                setOverflowing(false);
                return newScale;
            });
        }
    }, []);
    return <div
        className={classNames("argument-text", { overflowing })}
        style={{ fontSize: `${Math.pow(2, scale / 2)}em` }}
        ref={element => {
            ref.current = element ?? undefined;
            if (element && lastSizeRef.current !== element.clientHeight) {
                updateFontSize();
            }
        }}
    >
        {children}
    </ div>
}

const md = new MardownIt("zero", { linkify: true, typographer: true });
md.enable([
    "link",
    "linkify",
    "autolink",
    "emphasis",
    "strikethrough",
    "balance_pairs",
    "backticks",
    "entity",
    "text",
    "text_collapse"
])
const defaultLinkRender = md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
};

md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
    const link = tokens[idx];
    // Open in new tab
    link.attrSet("target", "_blank");
    // Prepend http:// if no protocol is given
    const href = link.attrGet("href") ?? "";
    const protocolRegex = /^\w+:/;
    if (!protocolRegex.test(href)) {
        link.attrSet("href", "http://" + href);
    }
    return defaultLinkRender(tokens, idx, options, env, self);
}

/** Displays hyperlinks as links. */
function MarkdownInline(props: { rawText: string }) {
    const parsed = md.renderInline(props.rawText, null);
    return <span dangerouslySetInnerHTML={{ __html: parsed }} />
}
