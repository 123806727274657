import { configureStore, ThunkAction, Action, combineReducers, createAction, AnyAction } from '@reduxjs/toolkit';
import { argumentsSlice } from '../features/arguments/argumentsSlice';
import { languageSlice } from '../features/i18n';
import { legendSlice } from '../features/legend/legendSlice';
import { patternsSlice } from '../features/patterns/patternsSlice';
import { loadSettings, saveSettingsMiddleware } from '../features/save-settings/saveSettings';
import { colorThemeSlice } from '../features/switch-color-theme/colorThemeSlice';
import { titleSlice } from '../features/title/titleSlice';
import { zenModeSlice } from '../features/zen-mode/zenModeSlice';

const baseReducer = combineReducers({
  arguments: argumentsSlice.reducer,
  titles: titleSlice.reducer,
  patternsActive: patternsSlice.reducer,
  legend: legendSlice.reducer,
  language: languageSlice.reducer,
  colorTheme: colorThemeSlice.reducer,
  zenMode: zenModeSlice.reducer,
});

export const setState = createAction<RootState>("setState");
export const clearAll = createAction("clearAll");

const reducer = (state: RootState | undefined, action: AnyAction): RootState => {
  if (action.type === setState.type) {
    const typedAction = (action as ReturnType<typeof setState>);
    return typedAction.payload;
  }
  else if (action.type === clearAll.type) {
    return baseReducer(undefined, action);
  }

  return baseReducer(state, action);
};

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddlewares => [
    ...getDefaultMiddlewares(),
    saveSettingsMiddleware
  ],
});

loadSettings();


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof baseReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

if (process.env.NODE_ENV === "development")
  (window as any).store = store;
