import { Translations } from "../i18n";
import "./changelog.css"

export const changelog = (t: Translations) => <>
    <h1>{t.whatsNew}</h1>

    {t.changelogContent}
</>;

const currentVersion = "0.8.1";
export const showChangeLog = localStorage.getItem("lastVersion") !== currentVersion;
localStorage.setItem("lastVersion", currentVersion);
