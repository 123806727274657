import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../../app/hooks";
import { deDe } from "./deDe";
import { enUs } from "./enUs";

export type Translations = typeof enUs;
export const languages = { deDe, enUs };
export type Language = keyof typeof languages;
export const languageSlice = createSlice({
    name: "language",
    initialState: "deDe" as Language,
    reducers: {
        setLanguage(state, action: PayloadAction<Language>) {
            return action.payload;
        }
    }
});

export const { setLanguage } = languageSlice.actions;

export const useTranslations = () => {
    const language = useAppSelector(state => state.language);
    return languages[language];
}
