import { createSlice } from "@reduxjs/toolkit";

export type ColorScheme = "dark" | "light";
export const colorThemeSlice = createSlice({
    name: "colorTheme",
    initialState: (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light") as ColorScheme,
    reducers: {
        toggleTheme(state) {
            return state === "dark" ? "light" : "dark";
        }
    }
});
export const { toggleTheme } = colorThemeSlice.actions;
