import { faFolderOpen, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setState } from "../../app/store";
import { useTranslations } from "../i18n";
import "./buttons.css"

export function SaveButton() {
    const [download, setDownload] = React.useState(false);
    const t = useTranslations();

    return <div className="icon-button save-button" onClick={() => setDownload(true)} title={t.save}>
        <FontAwesomeIcon icon={faSave} />
        {download && <DownloadComponent onDownload={() => setDownload(false)} />}
    </div>
}

function DownloadComponent(props: { onDownload: () => void }) {
    const wholeState = useAppSelector(state => state);
    const dataUrl = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(wholeState));
    const downloadName =
        "Argumentationswippe-" +
        wholeState.titles.title
            .replaceAll(/\W/g, "-")
            .replaceAll(/--+/g, "-")
            .substring(0, 20)
            .replaceAll(/(^-+|-+$)/g, "")
        + ".json";

    return <a
        href={dataUrl}
        download={downloadName}
        ref={x => {
            x?.click();
            props.onDownload();
        }}
        style={{ display: "none" }}
    >
        dummy
    </a>
}

export function LoadButton() {
    const fileDialogRef = React.useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const t = useTranslations();

    return <div className="icon-button load-button" onClick={() => fileDialogRef.current?.click()} title={t.load}>
        <FontAwesomeIcon icon={faFolderOpen} />
        <input type="file" style={{ display: "none" }} ref={fileDialogRef} onChange={async e => {
            const text = await e.target.files?.item(0)?.text();
            if (text === undefined) {
                alert("Invalid file.");
                return;
            }
            const data = JSON.parse(text);
            dispatch(setState(data));
        }} accept=".json,application/json" />
    </div>
}
