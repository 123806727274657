import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { useTranslations } from '../i18n';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { toggleTheme } from './colorThemeSlice';

export function SwitchColorButton() {
    const currentTheme = useAppSelector(state => state.colorTheme);
    React.useEffect(() => {
        document.body.classList.remove("dark", "light");
        document.body.classList.add(currentTheme);
    }, [currentTheme]);
    const dispatch = useAppDispatch();
    const t = useTranslations();

    return <div
        onClick={() => dispatch(toggleTheme())}
        className="icon-button switch-color-button"
        title={currentTheme === "dark" ? t.switchToLightMode : t.switchToDarkMode}
    >
        {currentTheme === "light" ? <FontAwesomeIcon icon={faMoon} /> : <FontAwesomeIcon icon={faSun} />}
    </div>;
}
