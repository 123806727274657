import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useContentEditableProps } from "../../utils/useContentEditableProps";
import { ArgumentColor, defaultColor } from "../arguments/argumentsSlice";
import { useTranslations } from "../i18n";
import "./Legend.css"
import { setLegendText } from "./legendSlice";

export function Legend() {
    const t = useTranslations();

    const entries = useAppSelector(state => {
        const colors = new Set(Object.values(state.arguments).flat().map(x => x.color));
        const entries = (Object.entries(state.legend) as [ArgumentColor, string][])
            // don't show colors that do not have a name or an argument
            .filter(([k, v]) => v !== "" || colors.has(k));

        if (entries.length === 1 && entries[0][0] === defaultColor && entries[0][1] === "")
            // don't show a legend if no colors are used
            return [];

        return entries;
    });

    if (entries.length === 0) return <></>

    const legendEmpty = entries.every(x => x[1] === "");

    return <div className={classNames("legend", { legendEmpty })}>
        <h2>{t.legend}</h2>
        <div className="legend-entries">
            {entries.map(([k, v]) => <LegendEntry key={k} color={k} text={v} />)}
        </div>
    </div>
}

function LegendEntry(props: { color: ArgumentColor, text: string }) {
    const { color, text } = props;

    const dispatch = useAppDispatch();
    const contentEditableProps = useContentEditableProps(text => dispatch(setLegendText({ color, text })));
    const t = useTranslations();

    return <div className="legend-entry">
        <div className={`legend-entry-box argument-color-${color}`} />
        <span className="legend-entry-text" data-placeholder-text={t.emptyLegendItemPlaceholder} {...contentEditableProps}>
            {text}
        </span>
    </div>
}
