import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setTitle, TitleType } from "./titleSlice";
import { useContentEditableProps } from "../../utils/useContentEditableProps";

export function TitleComponent(props: { field: TitleType }) {
    const { field } = props;
    const title = useAppSelector(state => state.titles[field]);
    const dispatch = useAppDispatch();
    const contentEditableProps = useContentEditableProps(text => dispatch(setTitle({ field, text })));

    return <h1
        className={`title titles-${field}`}
    >
        <span className="title-input"
            {...contentEditableProps}>
            {title}
        </span>
        <span className="title-stretcher" />
    </h1>
}