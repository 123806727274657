import { faFile, faFolder, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translations } from ".";
import Imprint from "../footer/gwa-imprint/ReactImprint";

export const deDe: Translations = {
    clearAll: "Alles löschen",
    save: "Speichern",
    load: "Laden",
    enableZenMode: "Knöpfe ausblenden",
    disableZenMode: "Knöpfe einblenden",
    switchToDarkMode: "Zum dunklen Farbschema wechseln",
    switchToLightMode: "Zum hellen Farbschema wechseln",
    enablePatterns: "Muster anschalten",
    disablePatterns: "Muster abschalten",
    switchLanguage: "Sprache wechseln",
    legend: "Legende",
    emptyLegendItemPlaceholder: "Hier Text eingeben",
    editText: "Text ändern",
    howTo: "Wie's funktioniert",
    whatsNew: "Neuigkeiten",
    dataPrivacy: "Datenschutz",
    imprint: "Impressum",
    languageName: "de",
    howToText: <>
        <p>
            Dies ist ein Hilfsmittel zum Erlernen von (utillitaristischer) Argumentation im Klassenraum.
            Du kannst Argumente erstellen und sie an verschiedene Stellen auf die Wippe legen, welche dann entsprechend ausschwingt.
        </p>
        <p>
            Klicke das + in der Mitte um ein neues Argument hinzuzufügen.
            Du kannst Argumente ziehen und auf der Wippe ablegen.
            Wenn du ein Argument löschen willst, ziehe es auf das <FontAwesomeIcon icon={faTrash} />-Symbol.
        </p>
        <p>
            Wenn du die gesamte Wippte zurücksetzen willst, dann klicke auf den <FontAwesomeIcon icon={faFile} />-Knopf auf der rechten Seite.
        </p>
        <p>
            Du kannst sowohl den Titel als auch die Texte "Pro" und "Contra" anklicken und nach deinem Geschmack bearbeiten.
        </p>
        <p>
            Um die Wippe zu speichern und wieder zu laden, kannst du die Knöpfe <FontAwesomeIcon icon={faSave} /> und <FontAwesomeIcon icon={faFolder} /> links benutzen.
        </p>

        <br />
        Erstellt von <a href="https://wcke.de">Georg Wicke-Arndt</a><br />
        Idee von <a href="https://twitter.com/Fme_er">Frederic Meyer</a><br />
        Basierend auf <a href="https://www.westermann.de/anlage/4604900/" title="Jonas Tumbrink (2018): Argumentationswippe. Reflexion von Wertmaßstäben durch das Gewichten von Argumenten. In: Praxis Geographie (7/8), S. 36–39">Jonas Tumbrink (2018)</a>
    </>,
    imprintContent: <Imprint language="deDe" />,
    dataPrivacyContent: <>
        <p>
            Die Argumentationswippe ist i.A. datensparsam. Nach dem
            vollständigen Laden der Seite findet keine weitere Kommunikation mit
            dem Server statt.
        </p>
        <p>
            Daten, die beim nächsten Öffnen der Argumentationswippe erhalten
            bleiben sollen, wie z.B. die Sprach- oder Farbauswahl, werden im
            Browser gespeichert ("Local Storage"). Diese Daten werden nicht an
            den Server übermittelt.
        </p>

        <p>
            Alle anderen Inhalte der Argumentationswippe, d.h. die eingegebenen
            Argumente und Gewichtungen, werden überhaupt nicht gespeichert, von
            der manuellen Speicherung durch den Nutzer abgesehen.
        </p>

        <p>
            Aufrufe der Seite werden serverseitig gezählt. Diese Daten sind
            anonym, Rückschlüsse auf Einzelpersonen sind aufgrund der
            Aggregation nicht möglich.
        </p>

        <p>
            Seitenaufrufe werden dazu geloggt. Die Logeinträge enthalten keine
            persönlichen Daten wie IP-Addressen, sondern ausschließlich
            technische Details wie Art und Version verschiedener Protokolle und
            Menge der übertragenen Daten sowie den Zeitstempel. Die Logs werden
            nach sieben Tagen automatisch gelöscht.
        </p>

        <p>
            Die Seite wird von einer Privatperson betrieben. Eine
            Datenschutzerklärung im rechtlichen Sinne ist daher nicht notwendig.
            Dieser Text dient nur zur Information.
        </p>
    </>,
    changelogContent: <>
        <h2>v0.8.1 <i>14. September 2022</i></h2>

        Kleinere Änderungen:

        <ul>
            <li>Datenschutzerklärung hinzugefügt.</li>
            <li>
                Farben werden jetzt korrekt im Druck angezeigt (Strg+P).
                Dies kann genutzt werden, um die Seite als PDF zu speichern.
            </li>
        </ul>

        <h2>v0.8.0 <i>09. Februar 2022</i></h2>

        Größere Änderungen:

        <ul>
            <li>Die Argumente unterstützen jetzt <a href="https://de.wikipedia.org/wiki/Markdown" target="_blank" rel="noreferrer"><b>Markdown</b></a>. Das bedeutet, dass Links anklickbar werden und einfache Formatierungen wie z.B. <b>*fett*</b>, <i>**kursiv**</i> und <s>~~durchgestrichen~~</s> möglich sind. Auch Links mit eigenem Text sind möglich, die Syntax dazu ist [Eigener{"\u00A0"}Text](https://beispiel.de).</li>
            <li>Die Argumente können jetzt bearbeitet werden, in dem im Dropdown daneben <b>"Text ändern"</b> ausgewählt wird.</li>
        </ul>

        Kleinere Änderungen:

        <ul>
            <li>Die Legende wird im Zen-Modus platzsparender positioniert.</li>
            <li>Wenn ein Argumentkasten überläuft, wird dies nun grafisch angedeutet.</li>
            <li>Fehlende Übersetzungen eingefügt.</li>
        </ul>

        <h2>v0.7.0 <i>05. Februar 2022</i></h2>

        Größere Änderungen:

        <ul>
            <li>Eine <b>Legende</b> wurde hinzugefügt. Die Legende wird nur eingeblendet, wenn mehrere Farben benutzt werden.</li>
            <li><b>Übersetzungen</b> wurden hinzugefügt. Die Sprache kann nun mit dem untersten Knopf auf der rechten Seite gewechselt werden.</li>
            <li><b>Zen-Mode</b> hinzugefügt, bei dem alle Knöpfe ausgeblendet werden. Kann durch den obersten Knopf auf der rechten Seite aktiviert werden.</li>
        </ul>

        Kleinere Änderungen:

        <ul>
            <li>Die Argumente-Kästen haben jetzt eine konstante Höhe.</li>
            <li>Title-Texte hinzugefügt. Wenn der Mauszeiger über einem Knopf platziert wird, wird ein erklärender Text eingeblendet.</li>
            <li>Einstellungen (d.h. Farbschema, Muster, Sprache) werden nun lokal gespeichert.</li>
            <li>Kleinere graphische Anpassungen.</li>
            <li>Diverse Bugfixes.</li>
        </ul>


        <h2>v0.6.0 <i>30. Januar 2022</i></h2>

        Nur auf Englisch verfügbar.
    </>
};