//backwards to make them not searchable via ctrl+f
const allowed_hosts = [
    "ed.\\ekcw?).\\*.(", // (.*\\.)?wcke.de
    "tsohlacol).\\*.(", // (.*\\.)localhost
    "ed.\\eppiwsnoitatnemugra?).\\*.(" // (.*\\.)?argumentationswippe.de
]
if (process.env.NODE_ENV === "development") {
    allowed_hosts.push("tsohlacol") // localhost
}

function toArray(s: string) {
    const result = [];
    for (let i = 0; i < s.length; i++) {
        result.push(s[i]);
    }
    return result;
}

const regexes = allowed_hosts
    .map(x => [...toArray(x)].reverse().join(""))
    .map(x => new RegExp(`^${x}$`));

const domain = window.location.hostname;
const isLegalCopy = regexes.some(x => x.test(domain));
export default isLegalCopy;
