import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "./ColorDropdown.css";
import classNames from "classnames";
import { argumentColors, ArgumentIndex, setArgumentColor } from "./argumentsSlice";
import { useAppDispatch } from "../../app/hooks";
import { useTranslations } from "../i18n";

type DropdownState = "closed" | { top: number, left: number }

export function ColorDropdownButton(props: { argument: ArgumentIndex, startEdit: () => void }) {
    const [state, setState] = React.useState<DropdownState>("closed");

    return <>
        <div
            className={classNames("argument-color-dropdown-button", { "open": state !== "closed" })}
            onClick={(e) => {
                if (state !== "closed") {
                    setState("closed");
                    return;
                }

                const rect = e.currentTarget.getBoundingClientRect();
                const top = rect.top + rect.height;
                const left = rect.left;
                setState({ top, left });
            }}>
            <FontAwesomeIcon icon={faChevronDown} />
        </div>
        {state !== "closed" && ReactDOM.createPortal(
            <div className="color-dropdown-wrapper" onClick={() => setState("closed")}>
                <div className="color-dropdown" style={{ ...state }} onClick={e => e.stopPropagation()}>
                    <ColorDropdown closePopup={() => setState("closed")} {...props} />
                </div>
            </div>, document.getElementById("dropdown-container")!)}
    </>
}

function ColorDropdown(props: { argument: ArgumentIndex, closePopup: () => void, startEdit: () => void }) {
    const { argument, closePopup, startEdit } = props;
    const dispatch = useAppDispatch();
    const t = useTranslations();

    return <>
        {argumentColors.map(color => <div
            key={color}
            className={classNames("color-dropdown-choice", `argument-color-${color}`)}
            onClick={() => {
                dispatch(setArgumentColor({ target: argument, color }))
                closePopup()
            }}
        />)}
        <div className="edit-text-button" onClick={() => { startEdit(); closePopup() }}>{t.editText}</div>
    </>
}
