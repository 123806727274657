import { createSlice } from "@reduxjs/toolkit";

export const zenModeSlice = createSlice({
    name: "zenMode",
    initialState: false,
    reducers: {
        toggleZenMode(state) {
            return !state;
        }
    }
});
export const { toggleZenMode } = zenModeSlice.actions;
