import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { useTranslations } from "../i18n";
import { toggleZenMode } from "./zenModeSlice";
import "./zenMode.css"

export function ZenModeButton() {
    const zenMode = useAppSelector(state => state.zenMode);
    React.useEffect(() => {
        document.body.classList.remove("zen");
        if (zenMode)
            document.body.classList.add("zen");
    }, [zenMode]);
    const dispatch = useDispatch();
    const t = useTranslations();

    return <div
        className="icon-button zen-mode-button"
        onClick={() => dispatch(toggleZenMode())}
        title={zenMode ? t.enableZenMode : t.disableZenMode}>
        <FontAwesomeIcon icon={zenMode ? faCompress : faExpand} />
    </div>
}