import { faFile, faFolder, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Imprint from "../footer/gwa-imprint/ReactImprint";

export const enUs = {
    clearAll: "Clear All",
    save: "Save",
    load: "Load",
    enableZenMode: "Hide buttons",
    disableZenMode: "Show buttons",
    switchToDarkMode: "Switch to dark mode",
    switchToLightMode: "Switch to light mode",
    enablePatterns: "Enable patterns",
    disablePatterns: "Disable patterns",
    switchLanguage: "Switch language",
    legend: "Legend",
    emptyLegendItemPlaceholder: "Type text here",
    editText: "Edit text",
    howTo: "How To",
    whatsNew: "What's New",
    dataPrivacy: "Data Privacy",
    imprint: "Imprint",
    languageName: "en",
    howToText: <>
        <p>
            This is a tool to teach argumentation in a utillitarian style in front of class.
            You can create arguments and put them onto different places on the seesaw, which will then swing accordingly.
        </p>
        <p>
            Click the + in the center to add a new argument.
            You can drag and drop the arguments onto the seesaw.
            If you want to delete an argument, drag it onto the <FontAwesomeIcon icon={faTrash} /> icon in the bottom.
        </p>
        <p>
            If you want to reset the entire seesaw, click the <FontAwesomeIcon icon={faFile} /> button on the right.
        </p>
        <p>
            You can click the title as well as the "Pro" and "Contra" texts and edit them as you wish.
        </p>
        <p>
            To save your seesaw and load it again, you can use the <FontAwesomeIcon icon={faSave} /> and <FontAwesomeIcon icon={faFolder} /> buttons on the left.
        </p>

        <br />
        Created by <a href="https://wcke.de">Georg Wicke-Arndt</a><br />
        Idea by <a href="https://twitter.com/Fme_er">Frederic Meyer</a><br />
        Based on <a href="https://www.westermann.de/anlage/4604900/" title="Jonas Tumbrink (2018): Argumentationswippe. Reflexion von Wertmaßstäben durch das Gewichten von Argumenten. In: Praxis Geographie (7/8), S. 36–39">Jonas Tumbrink (2018)</a>
    </>,
    imprintContent: <Imprint language="enUs" />,
    dataPrivacyContent: <>
        <p>
            The Argumentationswippe ist generally data-saving. After the initial
            load is completed, no further communication with the server takes
            place.
        </p>
        <p>
            Some data that is kept between usages of the application, like
            choice of language or color, is being saved in the browser
            ("local storage"). This data is not transferred to the server.
        </p>

        <p>
            All other content of the site, e.g. entered arguments and their
            weights, are not saved at all, apart from manual saving by the user.
        </p>

        <p>
            Accesses to the page are counted by the server. This data is
            anonymous; conclusions about individuals are not possible due to
            aggregation.
        </p>

        <p>
            Accesses to the page are also logged. The log entries contain
            information about the time of the access and various technical
            details like protocols used and amount of data transmitted, but no
            personal data like IP addresses. These logs are automatically
            deleted after seven days.
        </p>

        <p>
            The site is operated by a private person. A data protection
            declaration in the legal sense is therefore not necessary. This text
            is for information purposes only.
        </p>
    </>,
    changelogContent: <>
        <h2>v0.8.1 <i>2022-09-14</i></h2>

        Minor changes:

        <ul>
            <li>Added data privacy notes.</li>
            <li>
                Colors are now visible correctly in Print (Ctrl+P).
                This can be used to save the page as a PDF.
            </li>
        </ul>

        <h2>v0.8.0 <i>2022-02-09</i></h2>

        Major changes:

        <ul>
            <li>Arguments now support <a href="https://en.wikipedia.org/wiki/Markdown" target="_blank" rel="noreferrer"><b>Markdown</b></a>. This means that links become clickable and simple formatting like <b>*bold*</b>, <i>**italics**</i> and <s>~~strikethrough~~</s> are possible. Links with custom texts are possible as well, the syntax is [Custom{"\u00A0"}Text](https://example.com).</li>
            <li>Arguments can now be edited by selecting <b>"Edit Text"</b> in the dropdown.</li>
        </ul>

        Minor changes:

        <ul>
            <li>The legend is now positioned more efficiently in Zen mode.</li>
            <li>Arguments display a graphical hint when they overflow.</li>
            <li>Added missing translations.</li>
        </ul>

        <h2>v0.7.0 <i>2022-02-05</i></h2>

        Major changes:

        <ul>
            <li>Added a <b>legend</b>. The legend is only displayed when colors are being used.</li>
            <li>Added <b>translations</b>. You can now switch the language with last button on the right side.</li>
            <li>Added <b>Zen mode</b>, which hides all buttons. Can be activated by the first button on the right side.</li>
        </ul>

        Minor changes:

        <ul>
            <li>Argument boxes now have a constant height.</li>
            <li>Added title texts. When hovering a button with the mouse, an explanatory text will be displayed.</li>
            <li>Settings (i.e. color mode, patterns activated, language) are now being saved locally.</li>
            <li>Slight styling adjustments.</li>
            <li>Various bugfixes.</li>
        </ul>

        <h2>v0.6.0 <i>2022-01-30</i></h2>

        Major changes:

        <ul>
            <li>Arguments now have selectable <b>colors</b>.
                Alternatively, <b>patterns</b> can be displayed to help
                distinguishing them in bad conditions or in case of colorblindness.
            </li>
            <li>
                The <b>UI</b> was rearranged for clarity.
            </li>
        </ul>

        Minor changes:

        <ul>
            <li>The transition between light and dark mode is now softer.</li>
            <li>After writing an argument, the block is now created when clicking outside of the text area.</li>
            <li>Added a donation button in "How To".</li>
            <li>Long titles are no longer overflowing on top of other elements.</li>
            <li>The formatting is not copied any more when pasting from an external source.</li>
            <li>Fixed various other graphical glitches.</li>
            <li>Added a changelog. :-)</li>
        </ul>
    </>
};
