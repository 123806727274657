import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { HashRouter, useLocation, useNavigate } from "react-router-dom";

import "./Footer.css";
import ReactDOM from "react-dom";
import { DonationImage } from "../donations";
import { changelog, showChangeLog } from "./Changelog";
import React from "react";
import { Translations, useTranslations } from "../i18n";

export function Footer() {
    const t = useTranslations();

    return <>
        <div className="footer-wrapper">
            <footer>
                <a href="#/how-to">{t.howTo}</a> ·
                <a href="#/changelog">{t.whatsNew}</a> ·
                <a href="#/data-privacy">{t.dataPrivacy}</a> ·
                <a href="#/imprint">{t.imprint}</a>
            </footer>
        </div>
        <Modals />
    </>
}

function Modals() {
    return ReactDOM.createPortal(<HashRouter>
        <Modal id="changelog" activeByDefault={showChangeLog} />
        <Modal id="how-to" />
        <Modal id="data-privacy" />
        <Modal id="imprint" />
    </HashRouter>, document.getElementById("modal-container")!);
}

const modalTexts = {
    "how-to": (t: Translations) => <>
        <h1>{t.howTo}</h1>
        {t.howToText}

        <br />

        <br />
        <br />

        <DonationImage />
    </>,
    "imprint": (t: Translations) => <>
        <h1>{t.imprint}</h1>

        {t.imprintContent}
    </>,
    "data-privacy": (t: Translations) => <>
        <h1>{t.dataPrivacy}</h1>
        {t.dataPrivacyContent}
    </>,
    "changelog": changelog
}

function Modal(props: { id: keyof typeof modalTexts, activeByDefault?: boolean }) {
    const { id, activeByDefault } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const pathname = `/${id}`;
    const isActive = location.pathname === pathname;
    const [wasActivated, setWasActivated] = React.useState(false);
    const t = useTranslations();

    React.useEffect(() => {
        if (activeByDefault && location.pathname === "/" && !wasActivated) {
            navigate({ pathname });
            setWasActivated(true);
        }
    }, [activeByDefault, location, navigate, pathname, wasActivated, setWasActivated]);

    return <div
        className={classNames("modal", id, { isActive })}
        onClick={e => {
            if (e.target === e.currentTarget)
                window.location.hash = "#"
        }}
    >
        <div className="modal-inner">
            <div className="icon-button" onClick={() => window.location.hash = "#"}>
                <FontAwesomeIcon icon={faTimes} />
            </div>
            {modalTexts[id](t)}
        </div>
    </div>
}
